<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <v-card>
      <snack-bar ref="snackBar" />
      <v-dialog
        v-model="rmaCustomerDetailDialog"
        transition="dialog-top-transition"
        max-width="800"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            {{ rmaCustomerName }}
          </v-toolbar>
          <v-data-table
            :headers="customerRmaOrderHeader"
            :items="customerRmaOrderData"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Customer RMA Item</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
          <h5 class="ml-5">
            *Recent bought count from {{ salesDataCountFrom }}
          </h5>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="rmaCustomerDetailDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--
      <v-toolbar
        flat
      >
        <v-tabs>
          <v-tab @click="changeDisplay('item_table')">Rma Data Table</v-tab>
          <v-tab @click="changeDisplay('report_table')">Rma Data Report</v-tab>
        </v-tabs>
        <v-btn
          class="mr-0"
        >
        </v-btn>
      </v-toolbar>
      -->
      <v-card-actions>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="rmaDate.date_from"
            type="date"
            label="Rma Date From"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="rmaDate.date_to"
            type="date"
            label="Date To"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-btn
            class="ma-2"
            :loading="loadingRmaData"
            :disabled="loadingRmaData"
            color="info"
            @click="loadRmaData()"
          >
            Search Data
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="salesDataCountFrom"
            type="date"
            label="Sales Data Count From"
            disabled
          />
        </v-col>
      </v-card-actions>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="9"
          >
            <v-data-table
              dense
              :headers="rmaItemTableHeader"
              :items="rmaItemTableData"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Item Report</v-toolbar-title>
                </v-toolbar>
              </template>
            </v-data-table>
            <v-data-table
              dense
              :headers="rmaCustomerTableHeader"
              :items="rmaCustomerTableData"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Customer Report</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="showCustomerRmaDetail(item)"
                >
                  mdi-clipboard-text
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-col cols="12">
              <v-btn
                block
                :loading="loadingRmaDataFromSos"
                :disabled="loadingRmaDataFromSos"
                @click="syncRmaInfoFromSos()"
              >
                Sync SOS Data
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
            <pie-chart
              :chart-data="rmaTypeData"
              :options="basePieChart"
            />
            <v-col
              cols="12"
            >
              <v-data-table
                dense
                :headers="returnReasonHeader"
                :items="returnReasonData"
                class="xm-2"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>Return Reason</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import SnackBar from '@/components/app/SnackBar'
  import salesRmaApi from '@/api/sales-rma'
  import PieChart from '@/util/charts/pie'
  import ChartDataHandle from '@/util/chart-data-handle'
  import store from '@/store'
  export default {
    name: 'SalesRma',
    components: { SnackBar, PieChart },
    /*
    computed: {
      displayTable: function () {
        return this.display
      },
    },
    */
    data () {
      return {
        // display: 'item_table',
        rmaDate: {
          date_from: '',
          date_to: '',
        },
        salesDataCountFrom: '',
        loadingRmaData: false,
        rmaItemTableHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'item.sos_item_id',
          },
          { text: 'part_number', value: 'item.part_number' },
          { text: 'Type', value: 'type' },
          { text: 'DQ Times', value: 'dq_times' },
          { text: 'QTY', value: 'dq_total' },
          { text: 'DQ Passed', value: 'dq_passed' },
          { text: 'DQ Failed', value: 'dq_failed' },
          { text: 'Total Sales', value: 'total_invoiced' },
        ],
        rmaItemTableData: [],
        rmaCustomerTableHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'customer.id',
          },
          { text: 'Customer', value: 'customer.name' },
          { text: 'DQ Times', value: 'dq_times' },
          { text: 'DQ QTY', value: 'dq_items' },
          { text: 'DQ Passed', value: 'dq_items_passed' },
          { text: 'DQ Failed', value: 'dq_items_failed' },
          { text: 'Recent Amount $', value: 'bought_amount' },
          { text: 'Recent Bought', value: 'bought_invoiced' },
          { text: '', value: 'actions', sortable: false },
        ],
        rmaCustomerTableData: [],
        rmaCustomerDetailDialog: false,
        rmaCustomerName: '',
        customerRmaOrderHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'sos_item_id',
          },
          { text: 'part_number', value: 'part_number' },
          { text: 'Type', value: 'type' },
          { text: 'DQ Qty', value: 'quantity' },
          { text: 'Passed', value: 'returned' },
          { text: 'failed', value: 'failed' },
          { text: 'Recent Bought QTY', value: 'bought_invoiced' },
          { text: 'Amount', value: 'bought_amount' },
        ],
        customerRmaOrderData: [],
        returnReasonHeader: [
          {
            text: 'Reason',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Times', value: 'times' },
        ],
        returnReasonData: [],
        loadingRmaDataFromSos: false,
        basePieChart: {
          title: {
            display: true,
            text: 'Rma Type Report',
          },
        },
        rmaTypeData: {},
      }
    },
    created () {
      this.loadRmaData()
    },
    methods: {
      loadRmaData () {
        this.loadingRmaData = true
        salesRmaApi.salesRmaReport(this.rmaDate).then(response => {
          if (response.data.code === 200) {
            this.rmaItemTableData = response.data.data.item_report_item
            this.rmaCustomerTableData = response.data.data.customer_report
            this.rmaDate.date_from = response.data.data.date.rma_date_from
            this.rmaDate.date_to = response.data.data.date.rma_date_to
            this.salesDataCountFrom = response.data.data.date.sales_date_from
            this.returnReasonData = response.data.data.return_reason_report
            this.rmaTypePieData(response.data.data.item_report_type)
          }
        }).catch(() => {
          this.$router.push({ name: 'Error401' }).catch(() => {})
        }).finally(() => {
          this.loadingRmaData = false
        })
      },
      syncRmaInfoFromSos () {
        this.loadingRmaDataFromSos = true
        salesRmaApi.syncRmaReport().then(response => {
          if (response.data.code !== 200) {
            store.set('message/storeMessage', {
              color: response.data.type,
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        }).finally(() => {
          this.loadingRmaDataFromSos = false
          this.loadRmaData()
        })
      },
      rmaTypePieData (data) {
        const labelsInfo = []
        const datasets = [{ data: [], backgroundColor: [] }]
        data.forEach((value, index) => {
          labelsInfo.push(value.type + ' Qty: ' + value.dq)
          datasets[0].data.push(value.dq)
          datasets[0].backgroundColor.push(ChartDataHandle.randomTransparentColor())
        })
        this.rmaTypeData = {
          labels: labelsInfo, datasets,
        }
      },
      showCustomerRmaDetail (item) {
        this.rmaCustomerName = 'Customer: ' + item.customer.name
        this.customerRmaOrderData = item.item_info
        this.rmaCustomerDetailDialog = true
      },
      /*
      changeDisplay (displayTable) {
        this.display = displayTable
      },
      */
    },
  }
</script>

<style scoped>

</style>
