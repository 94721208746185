import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  salesRmaReport (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'salesRmaReport',
      data: data,
    })
  },

  syncRmaReport (recentMonth) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'salesRmaSync',
      param: {
        recent_month: recentMonth,
      },
    })
  },
}
